import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { AuthContext } from "./Context/AuthContext";

const Navigation = () => {
  const { isAuthenticated, companyName } = useContext(AuthContext);

  return (
    <div className="px-3">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to="/">
          {companyName || 'PartLine'}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/orders">
              Orders
            </Nav.Link>
            <Nav.Link as={Link} to="/parts">
              Parts
            </Nav.Link>
            <Nav.Link as={Link} to="/calendar">
              Calendar
            </Nav.Link>
            <Nav.Link as={Link} to={isAuthenticated ? "/account" : "/login"}>
              Account
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
