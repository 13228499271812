import React, { useState, useContext } from "react";
import { PartsContext } from "../Context/PartsContext";
import { Container, Alert, Form, Tabs, Tab } from "react-bootstrap";
import FileUpload from "./FileUpload";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios";
import customerData from "./customers_data.json";
import CreatableSelect from "react-select/creatable";
import CreatePartsGrid from "./CreatePartsGrid";
import { convertLocalMidnightToUtc } from "../utils/formatters";
import ManualPartsEntry from './ManualPartsEntry';
import { shouldDisableStep, filterStepsByProcessType } from '../utils/processTypeHelpers';

export function CreateOrderPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { parts: contextParts, setParts: setContextParts } =
    useContext(PartsContext);
  const [parts, setParts] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    customer_name: "",
    shipping_address: "",
    buyer_name: "",
    purchase_order_id: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const customerOptions = customerData.map((customer) => ({
    label: customer.customer_name,
    value: customer.customer_name,
    shipping_address: customer.shipping_address,
  }));

  const handleSelectChange = (selectedOption) => {
    if (selectedOption == null) {
      setOrderDetails({
        ...orderDetails,
        customer_name: "",
        shipping_address: "",
      });
      return;
    }
    
    if (selectedOption.__isNew__) {
      setOrderDetails({
        ...orderDetails,
        customer_name: selectedOption.value,
        shipping_address: "",
      });
    } else {
      setOrderDetails({
        ...orderDetails,
        customer_name: selectedOption.value,
        shipping_address: selectedOption.shipping_address,
      });
    }
  };

  const handleOrderChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails({
      ...orderDetails,
      [name]: value,
    });
  };

  const uploadOrder = async () => {
    const uploadParts = parts.map(part => ({
      ...part,
      steps: part.steps.filter(step => step.instructionId !== 'N/A'),
      due_date: convertLocalMidnightToUtc(part.due_date).toISOString()
    }));

    try {
      const response = await axiosInstance.post("/uploadOrder", {
        ...orderDetails,
        parts: uploadParts,
      });
      return response.data;
    } catch (error) {
      console.error("Failed to upload order:", error);
      throw error;
    }
  };

  const handleInstructionChange = (
    partIndex,
    stepId,
    instructionId,
    instruction
  ) => {
    setParts((prevParts) =>
      prevParts.map((part, i) =>
        partIndex === i
          ? {
              ...part,
              steps: part.steps.map((step) =>
                step.id === stepId
                  ? {
                      ...step,
                      instructionId: instructionId,
                      instruction: instruction,
                    }
                  : step
              ),
            }
          : part
      )
    );
  };

  const handleSampleData = () => {
    setParts((prevParts) =>
      prevParts.map((part) => {
        return {
          ...part,
          steps: part.steps.map((step) => {
            if (!step.instruction && !step.instructionId) {
              return {
                ...step,
                instructionId: "Example Instruction",
                instruction: "Bla bla instruction details",
              };
            }
            return step;
          }),
        };
      })
    );
  };

  const handleDueDateChange = (partIndex, newDueDate) => {
    setParts((prevParts) =>
      prevParts.map((part, i) =>
        partIndex === i ? { ...part, due_date: newDueDate } : part
      )
    );
  };

  const validateInstructions = () => {
    const incompleteParts = parts.filter((part) =>
      part.steps.some((step) => !step.instruction)
    );
    if (incompleteParts.length > 0) {
      setError(
        `The following parts are incomplete: ${incompleteParts
          .map((part) => part.name)
          .join(", ")}`
      );
      return false;
    }
    setError(null);

    if (
      orderDetails.customer_name == undefined ||
      orderDetails.customer_name == "" ||
      orderDetails.shipping_address == undefined ||
      orderDetails.shipping_address == "" ||
      orderDetails.buyer_name == undefined ||
      orderDetails.buyer_name == "" ||
      orderDetails.purchase_order_id == undefined ||
      orderDetails.purchase_order_id == ""
    ) {
      setError("Fill out customer & order details before submitting.");
      return false;
    }
    return true;
  };

  const validateParts = () => {
    if (!parts || parts.length === 0) {
      setError("Please upload at least one part");
      return false;
    }

    // // Validate due dates
    // const invalidDates = parts.filter(p => p.due_date && new Date(p.due_date) < new Date());
    // if (invalidDates.length > 0) {
    //   setError(`Parts with invalid due dates: ${invalidDates.map(p => p.part_unique_id).join(', ')}`);
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async () => {
    if (validateInstructions() && validateParts()) {
      setIsLoading(true);
      try {
        const res = await uploadOrder();
        setContextParts([...contextParts, ...(res.map(part => ({...part, due_date: convertLocalMidnightToUtc(part.due_date).toISOString() })))]);
        setSubmitted(true);
        navigate("/");
      } catch (err) {
        setError(err.message || "Failed to upload order");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeletePart = (partIndex) => {
    setParts((prevParts) => prevParts.filter((_, i) => i !== partIndex));
  };

  const handleEditPart = (
    partIndex, 
    newPartNumber, 
    newPartName, 
    newRevision,
    newQty,
    newPrice
  ) => {
    setParts((prevParts) =>
      prevParts.map((part, i) =>
        i === partIndex ? 
        { 
          ...part, 
          part_unique_id: newPartNumber, 
          name: newPartName,
          revision: newRevision,
          qty_ordered: newQty,
          unit_price: newPrice,
        } : part
      )
    );
  };

  const handleProcessTypeChange = (partIndex, processType) => {
    setParts((prevParts) =>
      prevParts.map((part, i) => {
        if (i === partIndex) {
          const updatedSteps = part.steps.map(step => {
            if (processType === 'inhouse') {
              // Reset all instructions for inhouse
              return {
                ...step,
                instructionId: '',
                instruction: ''
              };
            } else {
              // For other process types, handle disabled steps
              const isDisabled = shouldDisableStep(step.name, processType);
              return {
                ...step,
                instructionId: isDisabled ? 'N/A' : step.instructionId,
                instruction: isDisabled ? 'N/A' : step.instruction
              };
            }
          });

          return { 
            ...part, 
            process_type: processType,
            steps: updatedSteps
          };
        }
        return part;
      })
    );
  };

  return (
    parts && (
      <Container>
        <h2>Add Order Details</h2>
        <Form>
          <Form.Group controlId="customer_name">
            <Form.Label>Customer</Form.Label>
            <CreatableSelect
              isClearable
              options={customerOptions}
              value={customerOptions.find(
                (option) => option.value === orderDetails.customer_name
              )}
              onChange={handleSelectChange}
              placeholder="Select or create a customer"
              isSearchable
              formatCreateLabel={(inputValue) => `"${inputValue}"`}
            />
          </Form.Group>

          <Form.Group controlId="shipping_address">
            <Form.Label>Shipping Address</Form.Label>
            <Form.Control
              type="text"
              name="shipping_address"
              value={orderDetails.shipping_address}
              onChange={handleOrderChange}
              placeholder="Shipping address will auto-fill"
            />
          </Form.Group>

          <Form.Group controlId="buyer_name">
            <Form.Label>Buyer Name</Form.Label>
            <Form.Control
              type="text"
              name="buyer_name"
              value={orderDetails.buyer_name}
              onChange={handleOrderChange}
              placeholder="Enter buyer name"
            />
          </Form.Group>

          <Form.Group controlId="purchase_order_id">
            <Form.Label>Purchase Order ID</Form.Label>
            <Form.Control
              type="text"
              name="purchase_order_id"
              value={orderDetails.purchase_order_id}
              onChange={handleOrderChange}
              placeholder="Enter purchase order ID"
            />
          </Form.Group>
        </Form>

        <h3 className="mt-5">Add Parts to Order</h3>
        <Tabs defaultActiveKey="file" className="mb-3">
          <Tab eventKey="file" title="Upload File">
            <FileUpload 
              setParts={setParts} 
              setEditingIndex={setEditingIndex}
              setError={setError}
            />
          </Tab>
          <Tab eventKey="manual" title="Manual Entry">
            <ManualPartsEntry setParts={setParts} />
          </Tab>
        </Tabs>

        {error && (
          <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        {submitted && (
          <Alert variant="success" dismissible onClose={() => setSubmitted(false)}>
            Parts successfully saved!
          </Alert>
        )}
        
        {parts.length > 0 && (
          <CreatePartsGrid
            parts={parts}
            handleSubmit={handleSubmit}
            handleDueDateChange={handleDueDateChange}
            handleInstructionChange={handleInstructionChange}
            handleSampleData={handleSampleData}
            isLoading={isLoading}
            handleDeletePart={handleDeletePart}
            handleEditPart={handleEditPart}
            setEditingIndex={setEditingIndex}
            editingIndex={editingIndex}
            handleProcessTypeChange={handleProcessTypeChange}
          />
        )}
      </Container>
    )
  );
}
