import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { processNames } from "./Instructions";

const ManualPartsEntry = ({ setParts }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [newPart, setNewPart] = useState({
    part_unique_id: '',
    revision: '',
    name: '',
    due_date: new Date().toISOString().substring(0, 10),
    qty_ordered: 1,
    unit_price: 0,
    steps: processNames.map((name, index) => ({
      id: index + 1,
      name,
      status: "in progress",
    }))
  });


  const handleAddNewPart = () => {
    setParts(prevParts => [...prevParts, newPart]);
    setShowAddModal(false);
    setNewPart({
      part_unique_id: '',
      revision: '',
      name: '',
      due_date: new Date().toISOString().substring(0, 10),
      qty_ordered: 1,
      unit_price: 0,
        steps: processNames.map((name, index) => ({
        id: index + 1,
        name,
        status: "in progress",
      }))
    });
  };

  return (
    <div className="p-3">
      <Button 
        variant="success" 
        onClick={() => setShowAddModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} /> Add Line Item
      </Button>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Line Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Part Number *</Form.Label>
              <Form.Control
                required
                type="text"
                value={newPart.part_unique_id}
                onChange={(e) => setNewPart({...newPart, part_unique_id: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Revision</Form.Label>
              <Form.Control
                type="text"
                value={newPart.revision}
                onChange={(e) => setNewPart({...newPart, revision: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description *</Form.Label>
              <Form.Control
                required
                type="text"
                value={newPart.name}
                onChange={(e) => setNewPart({...newPart, name: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                value={newPart.due_date}
                onChange={(e) => setNewPart({...newPart, due_date: e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity *</Form.Label>
              <Form.Control
                required
                type="number"
                min="1"
                value={newPart.qty_ordered}
                onChange={(e) => setNewPart({...newPart, qty_ordered: parseInt(e.target.value) || 1})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Unit Price *</Form.Label>
              <Form.Control
                required
                type="number"
                step="0.01"
                min="0"
                value={newPart.unit_price}
                onChange={(e) => setNewPart({...newPart, unit_price: parseFloat(e.target.value) || 0})}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleAddNewPart}
            disabled={!newPart.part_unique_id || !newPart.name || newPart.qty_ordered < 1}
          >
            Add Part
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManualPartsEntry; 