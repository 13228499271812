import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Card, Alert } from "react-bootstrap";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import axiosInstance from "./axios";

const RegisterPage = () => {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [companyLocked, setCompanyLocked] = useState(false);
  const [isNewCompany, setIsNewCompany] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  // Get company from URL params if present
  useEffect(() => {
    const companyParam = searchParams.get("company");
    if (companyParam) {
      setCompany(decodeURIComponent(companyParam));
      setCompanyLocked(true);
      setIsNewCompany(false);
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    // Validation
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await axiosInstance.post("/register", { 
        username, 
        password, 
        company,
        status: isNewCompany ? 'active' : 'pending', // New companies are active immediately
        role: isNewCompany ? 'owner' : 'user', // New company registrants become owners
        isNewCompany
      });

      if (isNewCompany) {
        setMessage("Company registered successfully! You can now log in.");
      } else {
        setMessage("Registration successful! An admin will review your request.");
      }

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      setError(err.response?.data || "An error occurred during registration");
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center">Register</h2>
              {message && <Alert variant="success">{message}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}
              
              {!companyLocked && (
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="company-type-switch"
                    label="Register new company"
                    checked={isNewCompany}
                    onChange={(e) => setIsNewCompany(e.target.checked)}
                  />
                  {isNewCompany && (
                    <small className="text-muted">
                      You will be registered as the company owner
                    </small>
                  )}
                </Form.Group>
              )}

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCompany">
                  <Form.Label>
                    {isNewCompany ? "New Company Name" : "Company Name"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={isNewCompany ? "Enter new company name" : "Enter company name"}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    disabled={companyLocked}
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mb-3">
                  Register
                </Button>

                <div className="text-center">
                  <small>
                    Already have an account? <Link to="/login">Login here</Link>
                  </small>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterPage;
