import React, { useState, useContext } from "react";
import { Form, Container, Row, Col, Card, Alert } from "react-bootstrap";
import axiosInstance from "./axios";
import { AuthContext } from "./Context/AuthContext";
import Cookies from "js-cookie";
import LoadingButton from "./Buttons/LoadingButton";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setRole, isAuthenticated, login, setUsername: setAuthUsername, setId, setCompanyName } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const res = await axiosInstance.post("/login", { username, password });
      Cookies.set("jwt", res.data.token, {
        expires: 1,
        sameSite: "Strict",
      });
      setRole(res.data.role);
      setAuthUsername(res.data.username);
      setId(res.data.id);
      setCompanyName(res.data.companyName);
      login();
      navigate("/");
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data || "An error occurred during login. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return isAuthenticated ? (
    <h3>You're logged in</h3>
  ) : (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <h2 className="text-center">Login</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <LoadingButton
                  variant="primary"
                  onClick={handleSubmit}
                  text="Login"
                  loadingText="Logging in..."
                  className="w-100 mb-3"
                  isLoading={isLoading}
                />

                <div className="text-center">
                  <small>
                    Don't have an account? <Link to="/register">Register here</Link>
                  </small>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
