const locale = navigator.language;
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getLocalDate = (dateString) => {
  if (!dateString) return null;
  return new Date(dateString);
};

// e.g., 11/03/24, 08:45 PM
export const formatDateWithTime = (dateString) => {
  if (!dateString) return 'Unknown';
  const date = getLocalDate(dateString);
  
  return date.toLocaleString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });
};

// e.g., Monday, January 1, 2024
export const formatLongDate = (dateString) => {
  if (!dateString) return 'Unknown';
  const date = getLocalDate(dateString);
  
  return date.toLocaleString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone,
  });
};

// e.g., 01/01/24
export const formatDate = (dateString) => {
  if (!dateString) return 'Unknown';
  const date = getLocalDate(dateString);
  
  return date.toLocaleString(locale, {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    timeZone,
  });
};

// 2024-09-29
export const formatDateISO = (dateString) => {
  if (!dateString) return null;
  return dateString.split('T')[0];
};

// e.g., Dec 6th 00:00 PST -> Dec 6th 08:00 UTC
export const convertLocalMidnightToUtc = (dateString) => {
  if (!dateString) return null;
  const timezoneOffsetHours = new Date().getTimezoneOffset() / 60;
  const date = new Date(dateString);
  date.setUTCHours(timezoneOffsetHours, 0, 0, 0);
  return date;
};