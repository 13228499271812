import { Modal, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { formatLongDate } from '../utils/formatters';

function DayDetailsModal({ show, onHide, date, parts }) {
  const navigate = useNavigate();

  const partsForDate = parts.filter(part => {
    if (!part.due_date) return false;
    const dueDate = new Date(part.due_date);
    const selectedDate = new Date(date);
    return dueDate.getUTCFullYear() === selectedDate.getUTCFullYear() &&
           dueDate.getUTCMonth() === selectedDate.getUTCMonth() &&
           dueDate.getUTCDate() === selectedDate.getUTCDate();
  });

  const getStatusDisplay = (part) => {
    const currentStep = part.steps.find(step => step.status === 'in progress');
    const isComplete = !currentStep && part.steps.every(step => step.status === 'approved');
    const isRejected = part.steps.some(step => step.status === 'rejected');

    if (isComplete) {
      return (
        <div className="text-success">
          <small>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            Complete
          </small>
        </div>
      );
    }
    if (isRejected) {
      return (
        <div className="text-danger">
          <small>
            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
            Rejected
          </small>
        </div>
      );
    }

    // Calculate progress
    const currentStepIndex = part.steps.findIndex(step => step.status === 'in progress');
    return (
      <div className="text-secondary" style={{ minWidth: '150px' }}>
        <small>
            {part.steps[currentStepIndex].name}
        </small>
        <div className="progress mt-1" style={{ height: '4px' }}>
          <div 
            className="progress-bar" 
            style={{ 
              width: `${((currentStepIndex + 1) / part.steps.length) * 100}%` 
            }}
          />
        </div>
      </div>
    );
  };

  const handlePartClick = (partId) => {
    navigate(`/part/${partId}`);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {formatLongDate(date)}
          <small className="text-muted ms-2">
            ({partsForDate.length} {partsForDate.length === 1 ? 'part' : 'parts'})
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {partsForDate.length === 0 ? (
          <p className="text-muted">No parts due on this date.</p>
        ) : (
          <ListGroup>
            {partsForDate.map(part => (
              <ListGroup.Item 
                key={part.id}
                onClick={() => handlePartClick(part.id)}
                style={{ cursor: 'pointer' }}
                className="hover-bg-light"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">
                      {part.part_unique_id}
                      {/* {part.name && (
                        <small className="text-muted ms-2">
                          {part.name.length > 25 ? `${part.name.substring(0, 25)}...` : part.name}
                        </small>
                      )} */}
                    </h6>
                    <small className="text-muted">
                        <span className="me-2">
                            <strong>Order:</strong> {part.purchase_order_id}
                        </span>
                        <span className="me-2">
                            <strong>Customer:</strong> {part.customer_name}
                        </span>
                    </small>
                  </div>
                  {getStatusDisplay(part)}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DayDetailsModal;