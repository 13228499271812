import React, { useState, useContext } from "react";
import { Table, Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { instructionMap, stepToInstruction, processNames } from "./Instructions";
import { AuthContext } from "../Context/AuthContext";
import { shouldDisableStep } from '../utils/processTypeHelpers';

const CreatePartsGrid = ({
  parts,
  handleSubmit,
  handleDueDateChange,
  handleInstructionChange,
  handleSampleData,
  isLoading,
  handleDeletePart,
  handleEditPart,
  handleProcessTypeChange
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { username } = useContext(AuthContext);
  const [selectedPartIndex, setSelectedPartIndex] = useState(null);
  const [editedPartNumber, setEditedPartNumber] = useState("");
  const [editedPartName, setEditedPartName] = useState("");
  const [editedDueDate, setEditedDueDate] = useState("");
  const [editedRevision, setEditedRevision] = useState("");
  const [editedQty, setEditedQty] = useState(0);
  const [editedPrice, setEditedPrice] = useState(0);

  const handleEdit = (index) => {
    setSelectedPartIndex(index);
    setEditedPartNumber(parts[index].part_unique_id);
    setEditedPartName(parts[index].name);
    setEditedDueDate(parts[index].due_date || '');
    setEditedRevision(parts[index].revision || '');
    setEditedQty(parts[index].qty_ordered);
    setEditedPrice(parts[index].unit_price);
    setShowEditModal(true);
  };

  return (
    <div className="mt-3">
      <div style={{ overflowX: "auto" }}>
        <Table striped bordered hover size="sm" style={{ minWidth: "2200px" }}>
          <thead>
            <tr>
              <th style={{ minWidth: "150px" }}>Part Number</th>
              <th style={{ minWidth: "80px" }}>Revision</th>
              <th style={{ minWidth: "100px" }}>Due Date</th>
              <th style={{ minWidth: "120px" }}>Process Type</th>
              {processNames.map((name, index) => (
                <th key={index} style={{ minWidth: `${
                    (Math.max(name.length - 15, 0) * 10) + 150}px` }}>
                  {name}
                </th>
              ))}
              <th style={{ minWidth: "200px" }}>Description</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {parts.map((part, idx) => (
              <tr key={idx}>
                <td>{part.part_unique_id}</td>
                <td>{part.revision || 'N/A'}</td>
                <td>{part.due_date || 'Not set'}</td>
                <td>
                  <Form.Select
                    size="sm"
                    value={part.process_type || 'inhouse'}
                    onChange={(e) => handleProcessTypeChange(idx, e.target.value)}
                  >
                    <option value="inhouse">In House</option>
                    <option value="outsource">Outsource</option>
                  </Form.Select>
                </td>
                {part.steps.map((step, stepIndex) => (
                  <td key={stepIndex}>
                    <Form.Select
                      size="sm"
                      value={shouldDisableStep(step.name, part.process_type) ? "Select Step" : (step.instructionId || "")}
                      onChange={(e) => {
                        handleInstructionChange(
                          idx,
                          step.id,
                          e.target.value,
                          instructionMap[e.target.value]
                        );
                      }}
                      disabled={shouldDisableStep(step.name, part.process_type)}
                    >
                      <option value="">Select Step</option>
                      {stepToInstruction[stepIndex + 1]?.map((option, optIdx) => (
                        <option key={optIdx} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                ))}
                <td>{part.name}</td>
                <td>{part.qty_ordered}</td>
                <td>${part.unit_price.toFixed(2)}</td>
                <td>
                  <div className="d-flex gap-2">
                    <Button 
                      variant="outline-primary" 
                      size="sm"
                      onClick={() => handleEdit(idx)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                    <Button 
                      variant="outline-danger" 
                      size="sm"
                      onClick={() => handleDeletePart(idx)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <Button 
          variant="primary" 
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit Order"}
        </Button>
      {username === "MaximAlayev1" && (
        <Button variant="secondary" onClick={handleSampleData}>
          Fill Sample Data
        </Button>)}
      </div>


      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Part</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Part Number</Form.Label>
              <Form.Control
                type="text"
                value={editedPartNumber}
                onChange={(e) => setEditedPartNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Revision</Form.Label>
              <Form.Control
                type="text"
                value={editedRevision}
                onChange={(e) => setEditedRevision(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                value={editedPartName}
                onChange={(e) => setEditedPartName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                value={editedDueDate}
                onChange={(e) => setEditedDueDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={editedQty}
                onChange={(e) => setEditedQty(parseInt(e.target.value) || 1)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Unit Price</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                min="0"
                value={editedPrice}
                onChange={(e) => setEditedPrice(parseFloat(e.target.value) || 0)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={() => {
              handleEditPart(
                selectedPartIndex, 
                editedPartNumber, 
                editedPartName,
                editedRevision,
                editedQty,
                editedPrice
              );
              handleDueDateChange(selectedPartIndex, editedDueDate);
              setShowEditModal(false);
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreatePartsGrid;
