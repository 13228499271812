import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <section className="hero-section text-center py-5">
        <Container>
          <h1>PartLine</h1>
          <p className="lead mb-4 text-white">
            Simple order tracking for CNC machine shops
          </p>
          <p className="mb-5 text-white">
            Focus on what matters - making parts. PartLine helps you track orders and manage 
            production schedules without the complexity of all-in-one solutions. 
            Built specifically for small to medium-sized machine shops.
          </p>
          <Link to="/register">
            <Button variant="primary" size="lg" className="me-3">
              Register
            </Button>
          </Link>
          <Link to="/login">
            <Button variant="outline-light" size="lg">
              Login
            </Button>
          </Link>
        </Container>
      </section>

      <section className="features-section py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">Why Choose PartLine?</h2>
          <Row>
            <Col md={4} className="mb-4">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center">
                  <i className="bi bi-list-check display-4 mb-3"></i>
                  <Card.Title>Order Tracking</Card.Title>
                  <Card.Text>
                    Simple and focused order tracking from quote to completion. 
                    No unnecessary features, just what you need to stay organized.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center">
                  <i className="bi bi-calendar-check display-4 mb-3"></i>
                  <Card.Title>Production Schedule</Card.Title>
                  <Card.Text>
                    Clear calendar view of your production schedule. 
                    Know what's due and when without complex planning tools.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center">
                  <i className="bi bi-people display-4 mb-3"></i>
                  <Card.Title>Team Access</Card.Title>
                  <Card.Text>
                    Give your team the information they need with shared access 
                    to order details and production schedules.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pricing-section py-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className="mb-4">
              <Card className="h-100 border-0 shadow-sm">
                <Card.Body className="text-center">
                  <Card.Title className="display-6 mb-4">Professional</Card.Title>
                  <ul className="list-unstyled mt-4">
                    <li className="mb-3">Complete order management system</li>
                    <li className="mb-3">Production scheduling calendar</li>
                    <li className="mb-3">Multiple user accounts for your team</li>
                    <li className="mb-3">Secure cloud storage for order data</li>
                    <li className="mb-3">Priority email support</li>
                  </ul>
                  <Button 
                    variant="primary" 
                    className="w-100"
                    href="mailto:sales@partline.io"
                  >
                    Contact for Pricing
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <footer className="footer py-5 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <h4>PartLine</h4>
              <ul className="list-unstyled">
                <li><Link to="/refund-policy" className="text-white">Refund Policy</Link></li>
                <li><i className="bi bi-envelope-fill me-2"></i>support@partline.io</li>
              </ul>
            </Col>
            <Col>
              <p className="small text-white-50">
                PartLine is a focused order tracking solution for CNC machine shops. 
                We believe in doing one thing well - helping shops track their orders and schedules 
                without the complexity of all-in-one systems. All transactions are processed 
                securely through Stripe. By using our service, you agree to our refund policy. 
                For any questions, please contact our support team at support@partline.io.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage; 