import { useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Card, ListGroup, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { PartsContext } from "../../Context/PartsContext";
import { AuthContext } from "../../Context/AuthContext";
import BackButton from "../BackButton";
import { formatDate } from "../../utils/formatters";
import axiosInstance from "../../axios";
import { generateCoC } from './CoCGenerator';

function OrderDetails() {
  const { orderId } = useParams();
  const { parts, setParts } = useContext(PartsContext);
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const [showCoCModal, setShowCoCModal] = useState(false);

  const orderParts = parts.filter(p => p.order_id === parseInt(orderId));
  
  if (orderParts.length === 0) return null;
  
  const purchaseOrderId = orderParts[0].purchase_order_id;
  const customer = orderParts[0].customer_name;
  const totalParts = orderParts.length;
  const completedParts = orderParts.filter(part => 
    part.steps.every(step => step.status === "approved")
  ).length;

  const handleDeleteOrder = async () => {
    setDeleteLoading(true);
    try {
      await axiosInstance.delete(`/orders/${orderId}`);
      
      const updatedParts = parts.filter(p => p.order_id !== parseInt(orderId));
      setParts(updatedParts);

      navigate('/orders');
    } catch (error) {
      console.error("Failed to delete order:", error);
    } finally {
      setDeleteLoading(false);
      setShowDeleteModal(false);
    }
  };

  const handlePartSelection = (partId) => {
    setSelectedParts(prev => {
      if (prev.includes(partId)) {
        return prev.filter(id => id !== partId);
      }
      return [...prev, partId];
    });
  };

  const handleSelectAllParts = () => {
    if (selectedParts.length === orderParts.length) {
      setSelectedParts([]);
    } else {
      setSelectedParts(orderParts.map(part => part.id));
    }
  };

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <BackButton />
          </Col>
          <Col>
            <h4 className="mb-0">
              Order #{purchaseOrderId}
              <small className="text-muted ms-2">{customer}</small>
            </h4>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <strong>Customer:</strong> {customer}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Total Parts:</strong> {totalParts}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Completed Parts:</strong> {completedParts} ({Math.round(completedParts/totalParts * 100)}%)
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>

        {role === "admin" && (
          <Row className="mb-4">
            <Col>
              <div className="d-flex gap-2">
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Order
                </Button>
              </div>
            </Col>
          </Row>
        )}

        <div className="mb-4">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h5 className="mb-0">Parts in Order</h5>
            <Button 
              variant="outline-primary" 
              size="sm"
              onClick={() => setShowCoCModal(true)}
            >
              <FontAwesomeIcon icon={faFileDownload} className="me-1" />
              Generate CoC
            </Button>
          </div>
          <ListGroup>
            {orderParts.map((part) => {
              const isComplete = part.steps.every(step => step.status === "approved");
              const isRejected = part.steps.some(step => step.status === "rejected");
              const currentStep = part.steps.find(step => step.status === "in progress");
              
              return (
                <ListGroup.Item
                  key={part.id}
                  action
                  className="py-3"
                  onClick={() => navigate(`/part/${part.id}`)}
                >
                  <Row className="align-items-center">
                    <Col>
                      <h6 className="mb-1">{part.part_unique_id}</h6>
                      <div className="text-muted small">
                        <span className="me-3">
                          <strong>Due:</strong> {formatDate(part.due_date)}
                        </span>
                        <span>
                          <strong>Qty:</strong> {part.qty_ordered}
                        </span>
                      </div>
                    </Col>
                    <Col xs={3} className="text-end d-flex justify-content-end align-items-center">
                      {isComplete ? (
                        <div className="text-success">
                          <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                          Complete
                        </div>
                      ) : isRejected ? (
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                          Rejected
                        </div>
                      ) : (
                        <div className="text-secondary">
                          {currentStep ? `${currentStep.name}` : 'Pending'}
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      </Card.Body>

      {/* CoC Generation Modal */}
      <Modal 
        show={showCoCModal} 
        onHide={() => setShowCoCModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Certificate of Conformance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="mb-0">Select Parts for CoC</h6>
              <Button
                variant="link"
                size="sm"
                onClick={handleSelectAllParts}
                className="text-decoration-none"
              >
                {selectedParts.length === orderParts.length ? 'Deselect All' : 'Select All'}
              </Button>
            </div>
            <div className="border rounded p-3" style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {orderParts.map((part) => (
                <div 
                  key={part.id}
                  className="d-flex align-items-center py-2 border-bottom"
                >
                  <Form.Check
                    type="checkbox"
                    id={`part-${part.id}`}
                    checked={selectedParts.includes(part.id)}
                    onChange={() => handlePartSelection(part.id)}
                    label={
                      <div className="ms-2">
                        <div>{part.part_unique_id}</div>
                        <small className="text-muted">
                          Qty: {part.qty_ordered} | Rev: {part.revision || 'A-00'}
                        </small>
                      </div>
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <span className="text-muted">
              {selectedParts.length} part{selectedParts.length !== 1 ? 's' : ''} selected
            </span>
            <div>
              <Button 
                variant="secondary" 
                onClick={() => setShowCoCModal(false)}
                className="me-2"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  const selectedPartObjects = orderParts.filter(part => selectedParts.includes(part.id));
                  generateCoC(selectedPartObjects, purchaseOrderId, orderParts);
                  setShowCoCModal(false);
                }}
                disabled={selectedParts.length === 0}
              >
                Generate CoC
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete order #{purchaseOrderId}? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteOrder} disabled={deleteLoading}>
            {deleteLoading ? 'Deleting...' : 'Delete Order'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default OrderDetails; 