const instructionMap = {
    "Order Decision Option 1":
        "Outsource - If Option 1 selected then no selection is needed for the rest of the steps.",
    "Order Decision Option 2":
        "In House - If Option 2 selected, then we will select all options for the steps.",

    "Config Review":
        "Confirm configuration control of all documents associated with this order. Ensure the following information is accurate before starting operations:\n" +
        "  - Revision Check\n" +
        "  - Due Date\n" +
        "  - Equipment Plan\n" +
        "  - Material\n" +
        "  - Outside Processing Resource\n" +
        "  - Inspection plan\n" +
        "  - Risk analysis",

    "Purchase Material":
        "Purchase material from the AVL supplier:\n" +
        "  - Material / Size:\n" +
        "  - Qty:\n" +
        "  - Supplier:\n" +
        "  - External PO",

    "Material from Inventory": " - Material / Size:\n" + "  - Qty:",

    "Inspection needed":
        "Verify dimensions per specifications to customer and purchase order requirements. Ensure the following have been completed before the parts or material is released for production:\n" +
        "  - Dimensions are in the mean\n" +
        "  - Paperwork matches Purchase Order\n" +
        "  - Certificate of Conformance (if applicable) is correct and matches the actual material",

    "Inspection not needed": "No inspection needed.",

    "Cutting Needed":
        "Cut the raw material to the dimensions specified on the setup sheet. Verify the first pcs before cutting the full bar.",

    "N/A": "Not applicable.",

    "CNC Program":
        "Confirm configuration control of all documents associated with this order. Ensure the following information is accurate before programming:\n" +
        "  - Revision Check\n" +
        "  - Equipment Plan\n" +
        "  - Material\n" +
        "  - Tooling\n" +
        "  - Engraving\n" +
        "Generate Setup Sheet\n" +
        "Verify Program using camplete, Vericut, and MasterCam",

    "Machine Setup":
        "Setup machine per specifications. Ensure the following have been completed:\n" +
        "  - Cutting tools are correct\n" +
        "  - Cutting tools are secure\n" +
        "  - Cutting tools are sharp\n" +
        "  - Holding fixture is secure\n" +
        "  - Area is clean of any chips and dirt\n" +
        "  - Needed inspection devices are present and calibrated\n" +
        "  - Correct program is loaded\n" +
        "  - Correct raw material is pulled",

    "First Piece Inspection":
        "Inspect dimensions per specifications to customer requirements. Ensure the following have been completed before the part is released for production:\n" +
        "  - Dimensions are in the mean\n" +
        "  - Finishes are acceptable\n" +
        "  - No excessive burring",

    Production:
        "Perform periodic in-process inspection per the programmer's request and production visual board:\n" +
        "  - Dimensions are in the mean\n" +
        "  - Finishes are acceptable\n" +
        "  - No excessive burring\n" +
        "Maintain records using QC In-process sheet.\n" +
        "Maintain accurate part accounting, ensure to segregate non-conforming material while waiting for disposition.",

    "Outside Processing":
        "Schedule and identify the outside processing from the AVL supplier, and generate PO.",

    "Outside Processing Not needed": "Outside processing not needed.",

    "Final Inspection":
        "Verify dimensions per specifications to customer and purchase order requirements. Ensure the following have been completed before the parts or material is released for production:\n" +
        "  - Dimensions are in the mean\n" +
        "  - Paperwork matches Purchase Order\n" +
        "  - Certificate of Conformance (if applicable) is correct\n" +
        "  - Visual check for all masking/Color requirement",

    Assembly:
        "Order all hardware per drawing BOM from AVL suppliers.\n" +
        "Assemble all appropriate hardware per drawing guidelines, and ensure proper assembly by applicable test method.",

    "Assembly not needed": "Assembly not needed.",

    "FAI – Final Inspection":
        "Verify all inspection activities were per specifications to customer requirements. Ensure the following have been completed before the part is released for shipment:\n" +
        "  - Dimensions are within tolerance\n" +
        "  - Tools used for verification are identified\n" +
        "  - All material used has been verified\n" +
        "  - All outside processing certs have been verified\n" +
        "  - All operations have been documented and completed per planning",

    Packaging:
        "Package per customer requirement. Ensure parts are protected from one another. Ensure no Foreign Object Debris (FOD) exists in package.",

    "CoC needed":
        "Review the following information before issuance of a Certificate of Conformance to the customer:\n" +
        "  - Current Revision\n" +
        "  - Material Certification\n" +
        "  - Outside Processing Certifications\n" +
        "  - All inspection activities completed\n" +
        "  - Machining operations completed\n" +
        "  - Total quantities fabricated",

    "CoC Not needed": "CoC not needed.",

    Shipping: "Ship products per Customer Requirements to the PO address.",

    Custom: "Enter your custom instructions here...",
};

const stepToInstruction = {
    0: ["Outsource", "In House"],
    1: ["Config Review"],
    2: ["Purchase Material", "Material from Inventory"],
    3: ["Inspection needed", "N/A"],
    4: ["Cutting Needed", "N/A"],
    5: ["CNC Program"],
    6: ["Machine Setup"],
    7: ["First Piece Inspection"],
    8: ["Production"],
    9: ["Outside Processing", "N/A"],
    10: ["Inspection needed", "N/A"],
    11: ["Assembly", "N/A"],
    12: ["FAI – Final Inspection"],
    13: ["Packaging"],
    14: ["CoC needed", "N/A"],
    15: ["Shipping"],
};

const processNames = [
    "Configuration Review",
    "Purchase Material",
    "Material Receiving Inspection",
    "Saw Cutting",
    "Create CNC Program",
    "Machine Setup",
    "First Piece Inspection",
    "CNC-Mill Production",
    "Purchase Outside Processing",
    "Receiving Inspection",
    "Final Assembly",
    "Final Inspection",
    "Packaging",
    "Create Certificate of Conformance",
    "Shipping",
];

export { instructionMap, stepToInstruction, processNames };
