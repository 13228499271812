import { useContext, useState } from "react";
import { Container, Row, Col, Button, ListGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import PartFilter from "./PartFilter";
import { PartsContext } from "../Context/PartsContext";
import { AuthContext } from "../Context/AuthContext";
import { processNames } from "../Ordering/Instructions";
import { formatDate } from "../utils/formatters";

const truncateText = (text, maxLength = 25) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

function PartList() {
  const { parts } = useContext(PartsContext);
  const { hasMinimumRole } = useContext(AuthContext);
  const [filteredParts, setFilteredParts] = useState(parts);
  const [sortField, setSortField] = useState('due_date');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleFilter = (filteredParts) => {
    setFilteredParts(filteredParts);
  };

  const getStatusBadgeVariant = (status) => {
    if (status.startsWith('Finished')) return 'success';
    if (status.startsWith('Rejected')) return 'danger';
    return 'secondary';
  };

  const getPartStatus = (part) => {
    const rejectedStep = part.steps.find(step => step.status === "rejected");
    if (rejectedStep) {
      return `Rejected: ${rejectedStep.name}`;
    }

    const inProgressStep = part.steps.find(step => step.status === "in progress");
    if (inProgressStep) {
      return inProgressStep.name;
    }

    return "Finished";
  };

  const handleSort = (field) => {
    setSortDirection(current => current === 'asc' ? 'desc' : 'asc');
    setSortField(field);
  };

  const sortedParts = [...filteredParts].sort((a, b) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    if (sortField === 'due_date') {
      return direction * ((new Date(a.due_date || 0)) - (new Date(b.due_date || 0)));
    }
    return direction * (a[sortField] < b[sortField] ? -1 : 1);
  });

  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          {hasMinimumRole('admin') && (
            <Link to="/create-order">
              <Button variant="primary" className="mb-3 w-100">
                Create New Order
              </Button>
            </Link>
          )}
          <PartFilter parts={parts} onFilter={handleFilter} />
        </Col>

        <Col md={9}>
          <Row className="mb-3 align-items-center">
            <Col>
              <h2 className="mb-0">Parts</h2>
            </Col>
            <Col xs="auto">
              <Form.Select 
                onChange={(e) => handleSort(e.target.value)}
                value={sortField}
                className="me-2"
              >
                <option value="due_date">Due Date</option>
                <option value="part_unique_id">Part ID</option>
                <option value="purchase_order_id">Order Number</option>
                <option value="customer_name">Customer</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <Button 
                variant="outline-secondary"
                onClick={() => setSortDirection(d => d === 'asc' ? 'desc' : 'asc')}
                size="sm"
              >
                {sortDirection === 'asc' ? '↑' : '↓'}
              </Button>
            </Col>
          </Row>

          <ListGroup>
            {sortedParts.map((part) => {
              const status = getPartStatus(part);
              const isRejected = status.startsWith('Rejected');
              const isFinished = status === 'Finished';
              const dueDate = new Date(part.due_date);
              const isOverdue = dueDate < new Date() && !isFinished;
              
              return (
                <ListGroup.Item
                  key={part.id}
                  as={Link}
                  to={`/part/${part.id}`}
                  action
                  className={`py-3`}
                >
                  <Row>
                    <Col xs={12}>
                      <Row className="align-items-center">
                        <Col>
                          <h5 className="mb-1">{part.part_unique_id}</h5>
                          <div className="text-muted small">
                            <span className="me-3" title={part.name}>
                              <strong>Description:</strong> {truncateText(part.name)}
                            </span>
                            <span className="me-3">
                              <strong>Order:</strong> {part.purchase_order_id}
                            </span>
                            <span className="me-3">
                              <strong>Due:</strong> 
                              <span className={isOverdue ? 'text-danger fw-bold' : ''}>
                                {formatDate(part.due_date)}
                              </span>
                            </span>
                            <span><strong>Qty:</strong> {part.qty_ordered}</span>
                          </div>
                        </Col>
                        <Col xs={4} className="text-end">
                          {isFinished ? (
                            <div className="text-success">
                              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                              Complete
                            </div>
                          ) : isRejected ? (
                            <div className="text-danger">
                              <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                              {status}
                            </div>
                          ) : (
                            <div className="text-secondary">
                              In Progress: {status}
                              <div className="progress mt-1" style={{ height: '4px' }}>
                                <div 
                                  className="progress-bar" 
                                  style={{ 
                                    width: `${((part.steps.findIndex(s => s.status === "in progress") + 1) / part.steps.length) * 100}%` 
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default PartList;
