import { useContext, useState, useMemo, useCallback, useEffect } from "react";
import { Container, Row, Col, Button, ListGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { PartsContext } from "../../Context/PartsContext";
import { AuthContext } from "../../Context/AuthContext";
import { formatDate } from "../../utils/formatters";
import OrderFilter from "./OrderFilter";

function OrderList() {
  const { parts } = useContext(PartsContext);
  const { hasMinimumRole } = useContext(AuthContext);
  const [sortField, setSortField] = useState('due_date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filteredOrders, setFilteredOrders] = useState([]);

  const orders = useMemo(() => {
    return parts.reduce((acc, part) => {
      const orderId = part.order_id;
      if (!acc[orderId]) {
        acc[orderId] = {
          id: orderId,
          purchase_order_id: part.purchase_order_id,
          customer_name: part.customer_name,
          parts: [],
          earliest_due_date: new Date(part.due_date),
          latest_due_date: new Date(part.due_date)
        };
      }
      acc[orderId].parts.push(part);
      
      const dueDate = new Date(part.due_date);
      if (dueDate < acc[orderId].earliest_due_date) {
        acc[orderId].earliest_due_date = dueDate;
      }
      if (dueDate > acc[orderId].latest_due_date) {
        acc[orderId].latest_due_date = dueDate;
      }
      
      return acc;
    }, {});
  }, [parts]);

  const handleFilter = useCallback((filtered) => {
    setFilteredOrders(filtered);
  }, []);

  const sortedOrders = useMemo(() => {
    return [...filteredOrders].sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;
      switch (sortField) {
        case 'due_date':
          return direction * (a.earliest_due_date - b.earliest_due_date);
        case 'order_id':
          return direction * (a.id.localeCompare(b.id));
        case 'customer':
          return direction * (a.customer_name.localeCompare(b.customer_name));
        default:
          return 0;
      }
    });
  }, [filteredOrders, sortField, sortDirection]);

  const getOrderStatus = useMemo(() => (parts) => {
    const totalParts = parts.length;
    const completedParts = parts.filter(part => 
      part.steps.every(step => step.status === "approved")
    ).length;
    
    if (completedParts === totalParts) return "Complete";
    if (parts.some(part => part.steps.some(step => step.status === "rejected"))) {
      return "Has Rejections";
    }
    return "In Progress";
  }, []);

  useEffect(() => {
    if (Object.keys(orders).length > 0) {
      setFilteredOrders(Object.values(orders));
    }
  }, [orders]);

  const ordersArray = useMemo(() => {
    return Object.values(orders);
  }, [orders]);

  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          {hasMinimumRole('admin') && (
            <Link to="/create-order">
              <Button variant="primary" className="mb-3 w-100">
                Create New Order
              </Button>
            </Link>
          )}
          <OrderFilter 
            orders={ordersArray}
            onFilter={handleFilter}
            getOrderStatus={getOrderStatus} 
          />
        </Col>

        <Col md={9}>
          <Row className="mb-3 align-items-center">
            <Col>
              <h2 className="mb-0">Orders</h2>
            </Col>
            <Col xs="auto">
              <Form.Select 
                onChange={(e) => setSortField(e.target.value)}
                value={sortField}
                className="me-2"
              >
                <option value="due_date">Due Date</option>
                <option value="order_id">Order Number</option>
                <option value="customer">Customer</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <Button 
                variant="outline-secondary"
                onClick={() => setSortDirection(d => d === 'asc' ? 'desc' : 'asc')}
                size="sm"
              >
                {sortDirection === 'asc' ? '↑' : '↓'}
              </Button>
            </Col>
          </Row>

          <ListGroup>
            {sortedOrders.map((order) => {
              const status = getOrderStatus(order.parts);
              
              return (
                <ListGroup.Item
                  key={order.id}
                  as={Link}
                  to={`/order/${order.id}`}
                  action
                  className="py-3"
                >
                  <Row className="align-items-center">
                    <Col>
                      <h5 className="mb-1">Order #{order.purchase_order_id}</h5>
                      <div className="text-muted small">
                        <span className="me-3">
                          <strong>Customer:</strong> {order.customer_name}
                        </span>
                        <span className="me-3">
                          <strong>Parts:</strong> {order.parts.length}
                        </span>
                        <span>
                          <strong>Due:</strong> {formatDate(order.earliest_due_date)}
                          {order.earliest_due_date < order.latest_due_date && 
                            ` - ${formatDate(order.latest_due_date)}`}
                        </span>
                      </div>
                    </Col>
                    <Col xs={3} className="text-end">
                      {status === "Complete" ? (
                        <div className="text-success">
                          <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                          Complete
                        </div>
                      ) : status === "Has Rejections" ? (
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                          Has Rejections
                        </div>
                      ) : (
                        <div className="text-secondary">
                          In Progress
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderList; 