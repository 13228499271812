import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Form, Alert, Row, Col, Tooltip, OverlayTrigger, ListGroup, Modal } from "react-bootstrap";
import { PartsContext } from "../Context/PartsContext";
import { AuthContext } from "../Context/AuthContext";
import BackButton from "./BackButton";
import axiosInstance from "../axios";
import LoadingButton from "../Buttons/LoadingButton";
import { formatDateWithTime } from '../utils/formatters';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { generateCoC } from '../Tracking/Orders/CoCGenerator';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf-worker/pdf.worker.min.js';

function StepDetails() {
  const { partId, stepId } = useParams();
  const { parts, setParts } = useContext(PartsContext);
  const { username } = useContext(AuthContext);
  const [comment, setComment] = useState("");
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [nextStatus, setNextStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const part = parts.find((p) => p.id == partId);
  const step = part.steps.find((s) => s.id == stepId);

  const isFirstInProgressStep = part.steps.findIndex((s) => s.status === "in progress") === part.steps.indexOf(step);

  useEffect(() => {
    if (partId && step?.id) {
      fetchAttachments();
    }
  }, [partId, step?.id]);

  const fetchAttachments = async () => {
    try {
      const response = await axiosInstance.get(`/stepAttachments/${partId}/${step.id}`);
      setAttachments(response.data);
    } catch (error) {
      console.error("Failed to fetch attachments:", error);
      setErrorMessage("Failed to load attachments");
    }
  };

  const updateStatus = async (newStatus) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const formData = new FormData();
      formData.append('step_number', String(step.id));
      formData.append('part_id', String(partId));
      formData.append('status', newStatus);
      formData.append('comment', comment);
      
      selectedFiles.forEach(file => {
        formData.append('files', file);
      });

      const response = await axiosInstance.post("/updateStepStatus", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data.attachments?.length > 0) {
        setAttachments(prev => [...response.data.attachments, ...prev]);
      }

      updateStepsLocal(newStatus);
      setComment("");
      setSelectedFiles([]);
      setShowCommentBox(false);
    } catch (error) {
      console.error("Failed to update order:", error);
      setErrorMessage("Failed to update the status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateStepsLocal = (newStatus) => {
    const updatedParts = parts.map((p) => {
      if (p.id == partId) {
        return {
          ...p,
          steps: p.steps.map((s) =>
            s.id == stepId
              ? {
                  ...s,
                  status: newStatus,
                  comment,
                  updated_by: username,
                  updated_at: new Date().toISOString(),
                }
              : s
          ),
        };
      }
      return p;
    });
    setParts(updatedParts);
  };

  const handleApprove = () => updateStatus("approved");
  const handleReject = () => updateStatus("rejected");
  const handleUnApprove = () => updateStatus("in progress");
  const handleUnReject = () => updateStatus("in progress");

  const handleShowCommentBox = (s) => {
    setNextStatus(s);
    setShowCommentBox(true);
  };
  const handleHideCommentBox = () => {
    setShowCommentBox(false);
    setNextStatus(null);
    setComment("");
    setErrorMessage("");
  };

  const formatInstructions = (instructions) => {
    return instructions.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const getStepDisplayStatus = (step, steps, stepIndex) => {
    const hasRejectedSteps = steps.some(s => s.status === "rejected");
    const firstNonApprovedIndex = steps.findIndex(s => s.status !== "approved");
    
    if (step.status === "approved") return "approved";
    if (step.status === "rejected") return "rejected";
    
    if (hasRejectedSteps || (firstNonApprovedIndex !== -1 && stepIndex > firstNonApprovedIndex)) {
      return "not started";
    }
    
    return "in progress";
  };

  const getStatusIcon = (status) => {
    const displayStatus = getStepDisplayStatus(step, part.steps, part.steps.indexOf(step));
    
    if (displayStatus === "approved") {
      return (
        <div className="text-success">
          Approved
        </div>
      );
    } else if (displayStatus === "rejected") {
      return (
        <div className="text-danger">
          Rejected
        </div>
      );
    } else if (displayStatus === "not started") {
      return (
        <div className="text-secondary">
          Not Started
        </div>
      );
    }
    return (
      <div className="text-secondary">
        In Progress
      </div>
    );
  };

  const hasRejectedSteps = (steps, currentStepIndex) => {
    return steps
      .slice(0, currentStepIndex)
      .some((step) => step.status === "rejected");
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {hasRejectedSteps(part.steps, part.steps.indexOf(step))
        ? "Cannot approve/reject step when previous steps are rejected"
        : "You can only approve/reject the first step in progress"}
    </Tooltip>
  );

  const ActionButton = ({ variant, action, text }) => {
    const disabled = !isFirstInProgressStep || hasRejectedSteps(part.steps, part.steps.indexOf(step));
    const button = (
      <Button
        variant={variant}
        className="me-2"
        onClick={() => handleShowCommentBox(action)}
        disabled={disabled}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {text}
      </Button>
    );

    if (disabled) {
      return (
        <OverlayTrigger placement="top" overlay={renderTooltip}>
          <span className="d-inline-block">{button}</span>
        </OverlayTrigger>
      );
    }

    return button;
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const isPdf = (fileName) => {
    return fileName.toLowerCase().endsWith('.pdf');
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handlePreviewPdf = async (file) => {
    try {
      const response = await fetch(file.signedUrl);
      if (!response.ok) throw new Error('Preview failed');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      setSelectedPdfUrl(url);
    } catch (error) {
      console.error('Preview failed:', error);
      setErrorMessage('Failed to preview PDF. Please try again.');
    }
  };

  const FileList = ({ files, onRemove, isUploaded = false }) => {
    if (!files?.length) return null;

    const getFileName = (file) => {
        if (isUploaded) {
            const urlParts = file.file_url.split('/');
            const fullName = decodeURIComponent(urlParts[urlParts.length - 1]);
            const nameParts = fullName.split('-');
            if (nameParts.length > 2) {
                return nameParts.slice(2).join('-');
            }
            return fullName;
        }
        return file.name;
    };

    const handleDownload = async (file) => {
        try {
            const response = await fetch(file.signedUrl);
            if (!response.ok) throw new Error('Download failed');
            
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            
            const link = document.createElement('a');
            link.href = url;
            link.download = getFileName(file);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
            setErrorMessage('Failed to download file. Please try again.');
        }
    };

    return (
        <>
            <ListGroup className="mt-2">
                {files.map((file, index) => {
                    const fileName = getFileName(file);
                    const isPdfFile = isPdf(fileName);

                    return (
                        <ListGroup.Item 
                            key={isUploaded ? file.id : index}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                <i className="bi bi-paperclip me-2"></i>
                                {fileName}
                                {isUploaded && (
                                    <small className="text-muted ms-2">
                                        {new Date(file.created_at).toLocaleDateString()}
                                    </small>
                                )}
                            </div>
                            <div>
                                {isUploaded ? (
                                    <>
                                        {isPdfFile && (
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                                className="me-2"
                                                onClick={() => handlePreviewPdf(file)}
                                            >
                                                Preview
                                            </Button>
                                        )}
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => handleDownload(file)}
                                        >
                                            <i className="bi bi-download me-1"></i>
                                            Download
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => onRemove(index)}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>

            <Modal
                show={selectedPdfUrl !== null}
                onHide={() => setSelectedPdfUrl(null)}
                size="lg"
                dialogClassName="!max-w-[90%]"
            >
                <Modal.Header closeButton>
                    <Modal.Title>PDF Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="text-center max-h-[80vh] overflow-auto">
                        <Document
                            file={selectedPdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<span>Loading PDF...</span>}
                        >
                            <Page 
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                {...calculateOptimalScale()}
                            />
                        </Document>
                        {numPages && (
                            <div className="my-3">
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => setPageNumber(page => Math.max(1, page - 1))}
                                    disabled={pageNumber <= 1}
                                    className="mr-2"
                                >
                                    Previous
                                </Button>
                                <span className="mx-2">
                                    Page {pageNumber} of {numPages}
                                </span>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => setPageNumber(page => Math.min(numPages, page + 1))}
                                    disabled={pageNumber >= numPages}
                                    className="ml-2"
                                >
                                    Next
                                </Button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
  };

  const isCoCStep = step?.name.toLowerCase().includes('create certificate of conformance');

  const calculateOptimalScale = () => {
    const modalWidth = Math.min(window.innerWidth * 0.8, 800);
    const modalHeight = window.innerHeight * 0.6;
    return {
        width: modalWidth,
        height: modalHeight,
    };
  };

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <BackButton />
          </Col>
          <Col>
            <h4 className="mb-0">
              {step.name}
            </h4>
          </Col>
          <Col xs="auto">
            {getStatusIcon(step.status)}
          </Col>
        </Row>

        <Card.Text>
                <div className="me-2">
                  {formatInstructions(step.instruction)}
                </div>
            {isCoCStep && (
              <Button 
                variant="outline-secondary" 
                size="sm" 
                className="me-2 mt-2"
                onClick={() => generateCoC(part, part.purchase_order_id)}
              >
                <FontAwesomeIcon icon={faFileDownload} className="me-1" />
                Download CoC
              </Button>
            )}
        </Card.Text>

        {step.status !== "in progress" && (
          <>
            <Card.Text>
              <strong>Comment:</strong> {step.comment || "No comment provided"}
            </Card.Text>
            <Card.Text>
              <strong>Updated By:</strong> {step.updated_by || "Unknown"}
            </Card.Text>
            <Card.Text>
              <strong>Updated At:</strong> {formatDateWithTime(step.updated_at)}
            </Card.Text>
          </>
        )}

        {step.status === "in progress" && !showCommentBox && (
          <>
            <ActionButton 
              variant="success"
              action="approve"
              text="Approve"
            />
            <ActionButton 
              variant="danger"
              action="reject"
              text="Reject"
            />
          </>
        )}

        {step.status === "approved" && !showCommentBox && (
          <LoadingButton
            variant="warning"
            onClick={handleUnApprove}
            text="Un-Approve"
            loadingText="Un-Approving..."
            className="me-2"
            isLoading={isLoading}
            disabled={isLoading}
          />
        )}

        {step.status === "rejected" && !showCommentBox && (
          <LoadingButton
            variant="warning"
            onClick={handleUnReject}
            text="Un-Reject"
            loadingText="Un-Rejecting..."
            isLoading={isLoading}
            disabled={isLoading}
          />
        )}

        {showCommentBox && (
          <Form>
            <Form.Group controlId="commentBox">
              <Form.Label>
                <strong>Comment</strong>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                disabled={isLoading}
                onChange={(e) => setComment(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="fileUpload" className="mt-2 mb-2">
              <Form.Label>
                <strong>Attach Files (optional)</strong>
              </Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileSelect}
                disabled={isLoading}
              />
              <FileList 
                files={selectedFiles}
                onRemove={handleRemoveFile}
              />
            </Form.Group>
            <LoadingButton
              variant="primary"
              onClick={nextStatus == "approve" ? handleApprove : handleReject}
              text="Submit"
              loadingText="Submitting..."
              className="me-2 mt-2"
              isLoading={isLoading}
              disabled={isLoading}
            />
            <LoadingButton
              variant="secondary"
              onClick={handleHideCommentBox}
              text="Cancel"
              loadingText=""
              className="me-2 mt-2"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </Form>
        )}

        {attachments.length > 0 && (
          <div className="mt-3">
            <h5>Attachments</h5>
            <FileList 
              files={attachments}
              isUploaded={true}
            />
          </div>
        )}

        {/* Display the error message if there is one */}
        {errorMessage && (
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
}

export default StepDetails;
