import React from "react";
import PartList from "./Tracking/PartList";

const Home = () => {
  return (
    <div>
      {/* <h2>Home Page</h2>
      <p>Welcome to the Home Page!</p> */}
      <PartList />
    </div>
  );
};

export default Home;
