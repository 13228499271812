import { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../axios";
import { AuthContext } from "./AuthContext";

export const PartsContext = createContext();

export const PartsProvider = ({ children }) => {
  const [parts, setParts] = useState([]);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (!isAuthenticated) return;
    axiosInstance.get("/parts").then((res) => {
      if (res.status == "401") return;
      setParts(res.data);
    });
  }, [isAuthenticated]);

  return (
    <PartsContext.Provider value={{ parts, setParts }}>
      {children}
    </PartsContext.Provider>
  );
};
