import { useState, useContext, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { PartsContext } from '../Context/PartsContext';
import DayDetailsModal from './DayDetailsModal';
import './Calendar.css';

function CalendarView() {
  const { parts } = useContext(PartsContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

//   const events = parts.map(part => {
//     return {
//       title: part.part_unique_id,
//       date: part.due_date,
//       part: part,
//     };
//   });

  const events = Array.from(
    parts.reduce((map, part) => {
      const poId = part.purchase_order_id;
      const dueDate = part.due_date;
      const key = `${poId}-${dueDate}`;
        
      if (!map.has(key)) {
        map.set(key, {
          date: dueDate,
          part: part,
        });
      } else {
        map.get(key).partCount++;
      }
      return map;
    }, new Map()).values()
  );

  const handleDateClick = (arg) => {
    setSelectedDate(arg.date);
    setShowModal(true);
  };

  const handleEventClick = (info) => {
    setSelectedDate(info.event.start);
    setShowModal(true);
  };

  const renderEventContent = (eventInfo) => {
    const part = eventInfo.event.extendedProps.part;
    const currentStep = part.steps.find(step => step.status === 'in progress');
    const isComplete = !currentStep && part.steps.every(step => step.status === 'approved');
    const isRejected = part.steps.some(step => step.status === 'rejected');
    
    let progress = 0;
    if (isComplete) {
      progress = 100;
    } else if (!isRejected && currentStep) {
      const currentStepIndex = part.steps.findIndex(step => step.status === 'in progress');
      progress = ((currentStepIndex + 1) / part.steps.length) * 100;
    }

    return (
      <div className="event-content">
        <div className="event-title">{part.purchase_order_id}</div>
        <div className="event-progress-bar">
          <div 
            className="event-progress-fill"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        height="auto"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek'
        }}
        eventContent={renderEventContent}
        timeZone='local'
        displayEventTime={false}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: 'short'
        }}
      />

      {selectedDate && parts && (
        <DayDetailsModal 
          show={showModal}
          onHide={() => setShowModal(false)}
          date={selectedDate}
          parts={parts}
        />
      )}
    </>
  );
}

export default CalendarView; 