import React, { createContext, useState } from "react";

const ROLE_HIERARCHY = {
  owner: 3,
  admin: 2,
  user: 1
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [id, setId] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const hasMinimumRole = (requiredRole) => {
    const userRoleLevel = ROLE_HIERARCHY[role] || 0;
    const requiredRoleLevel = ROLE_HIERARCHY[requiredRole] || 0;
    return userRoleLevel >= requiredRoleLevel;
  };

  const login = () => setIsAuthenticated(true);
  const logout = () => {
    setIsAuthenticated(false);
    setRole(null);
    setUsername(null);
    setId(null);
    setCompanyName(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        setRole,
        role,
        username,
        setUsername,
        setId,
        userId: id,
        companyName,
        setCompanyName,
        hasMinimumRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
