import React from 'react';
import { Card } from 'react-bootstrap';

const RefundPolicy = () => {
  return (
    <Card className="border-0 shadow-sm mx-auto" style={{ maxWidth: '800px' }}>
      <Card.Body className="p-4 p-md-5">
        <h2 className="text-center mb-5">Refund Policy</h2>
          <h4 className="mb-3">Subscription Cancellation</h4>
          <p className="text-muted">
            You can cancel your subscription at any time through your account dashboard. 
            Upon cancellation:
          </p>
          <ul className="text-muted">
            <li className="mb-2">Your access will continue until the end of your current billing period</li>
            <li className="mb-2">No additional charges will be made to your account</li>
            <li className="mb-2">No partial refunds are provided for unused time in the current billing period</li>
          </ul>

          <h4 className="mb-3">Refund Eligibility</h4>
          <p className="text-muted">
            We offer refunds under the following circumstances:
          </p>
          <ul className="text-muted">
            <li className="mb-2">Within 30 days of initial paid subscription if you're not satisfied with the service</li>
            <li className="mb-2">Billing errors or duplicate charges</li>
            <li className="mb-2">Service unavailability lasting more than 24 hours</li>
          </ul>

          <h4 className="mb-3">How to Request a Refund</h4>
          <p className="text-muted">
            To request a refund, please contact our support team at <a href="mailto:support@partline.io">support@partline.io</a> with:
          </p>
          <ul className="text-muted">
            <li className="mb-2">Your account email address</li>
            <li className="mb-2">Reason for the refund request</li>
            <li className="mb-2">Date of charge</li>
          </ul>

          <h4 className="mb-3">Processing Time</h4>
          <p className="text-muted">
            Refund requests are typically processed within 5-7 business days. The actual time 
            for the refund to appear in your account may vary depending on your payment provider.
          </p>
      </Card.Body>
    </Card>
  );
};

export default RefundPolicy; 