const OUTSOURCE_SKIPPED_STEPS = [
    'Purchase Material',
    'Material Receiving Inspection',
    'Saw Cutting',
    'Create CNC Program',
    'Machine Setup',
    'First Piece Inspection',
    'CNC-Mill Production'
];

export const shouldDisableStep = (stepName, processType) => {
    return processType === 'outsource' && OUTSOURCE_SKIPPED_STEPS.includes(stepName);
};
