import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button size="sm" variant="" onClick={() => navigate(-1)}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </Button>
  );
};

export default BackButton;
