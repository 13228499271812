import { useState, useEffect } from "react";
import { Form, Card } from "react-bootstrap";
import Select from "react-select";

function PartFilter({ parts, onFilter }) {
  const [mainFilter, setMainFilter] = useState("all"); // Main filter (step, po, unique_id, status)
  const [stepFilter, setStepFilter] = useState(null); // Step filter (1-15)
  const [purchaseOrderFilter, setPurchaseOrderFilter] = useState(null); // For filtering by Purchase Order ID
  const [uniqueIdFilter, setUniqueIdFilter] = useState(null); // For filtering by Part Unique ID
  const [statusFilter, setStatusFilter] = useState("all"); // "all", "completed", "rejected", "in progress"

  // Get unique steps from all parts
  const uniqueSteps = Array.from(
    new Set(parts.flatMap(part => part.steps.map(step => step.name)))
  );

  // Deduplicate Purchase Order IDs and Part Unique IDs
  const uniquePurchaseOrders = Array.from(
    new Set(parts.map((part) => part.purchase_order_id))
  ).map((purchase_order_id) => ({
    value: purchase_order_id,
    label: `PO: ${purchase_order_id}`,
  }));

  const uniquePartIds = Array.from(
    new Set(parts.map((part) => part.part_unique_id))
  ).map((part_unique_id) => ({
    value: part_unique_id,
    label: `ID: ${part_unique_id}`,
  }));

  // Function to get the first in-progress step for a part
  const getFirstInProgressStep = (steps) => {
    return steps.find(step => step.status === "in progress");
  };

  useEffect(() => {
    const filteredParts = parts.filter((part) => {
      const inProgressStep = getFirstInProgressStep(part.steps);

      const stepFilterMatch = stepFilter
        ? inProgressStep?.name === stepFilter
        : true;

      // Filter by status (completed/rejected/in progress)
      const statusFilterMatch =
        statusFilter === "completed"
          ? part.steps.every((step) => step.status === "approved")
          : statusFilter === "rejected"
          ? part.steps.some((step) => step.status === "rejected")
          : statusFilter === "in progress"
          ? part.steps.some((step) => step.status === "in progress")
          : true;

      // Filter by purchase order id
      const purchaseOrderFilterMatch = purchaseOrderFilter
        ? part.purchase_order_id === purchaseOrderFilter.value
        : true;

      // Filter by unique id
      const uniqueIdFilterMatch = uniqueIdFilter
        ? part.part_unique_id === uniqueIdFilter.value
        : true;

      // Only include parts that match the currently active filter
      return (
        (mainFilter === "step" && stepFilterMatch) ||
        (mainFilter === "status" && statusFilterMatch) ||
        (mainFilter === "po" && purchaseOrderFilterMatch) ||
        (mainFilter === "unique_id" && uniqueIdFilterMatch) ||
        mainFilter === "all"
      );
    });

    // Pass filtered parts to the parent
    onFilter(filteredParts);
  }, [
    stepFilter,
    purchaseOrderFilter,
    uniqueIdFilter,
    statusFilter,
    parts,
    mainFilter,
  ]); // Dependencies: run whenever these values change

  // Reset filter values when switching the main filter
  const handleMainFilterChange = (filter) => {
    setMainFilter(filter);
    setStepFilter(null);
    setPurchaseOrderFilter(null);
    setUniqueIdFilter(null);
    setStatusFilter("all");
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <h4>Filter Parts</h4>

        {/* Main Filter Dropdown */}
        <Form.Group controlId="mainFilter" className="mb-3">
          <Form.Label>Select Filter:</Form.Label>
          <Form.Control
            as="select"
            value={mainFilter}
            onChange={(e) => handleMainFilterChange(e.target.value)}
          >
            <option value="all">All Parts</option>
            <option value="step">Filter by Last Step In Progress</option>
            <option value="po">Filter by Purchase Order ID</option>
            <option value="unique_id">Filter by Part ID</option>
            <option value="status">Filter by Status</option>
          </Form.Control>
        </Form.Group>

        {/* Updated Step Filter */}
        {mainFilter === "step" && (
          <Form.Group controlId="filterStep" className="mb-3">
            <Form.Label>Select Step:</Form.Label>
            <Form.Control
              as="select"
              value={stepFilter || ""}
              onChange={(e) => setStepFilter(e.target.value || null)}
            >
              <option value="">All Steps</option>
              {uniqueSteps.map((stepName) => (
                <option key={stepName} value={stepName}>
                  {stepName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {/* Conditional Render: Purchase Order ID Filter */}
        {mainFilter === "po" && (
          <Form.Group controlId="filterPurchaseOrder" className="mb-3">
            <Form.Label>Select Purchase Order ID:</Form.Label>
            <Select
              options={uniquePurchaseOrders}
              onChange={(selectedOption) =>
                setPurchaseOrderFilter(selectedOption)
              } // Update purchaseOrderFilter state
              isClearable
              placeholder="Select Purchase Order ID..."
            />
          </Form.Group>
        )}

        {/* Conditional Render: Unique ID Filter */}
        {mainFilter === "unique_id" && (
          <Form.Group controlId="filterUniqueId" className="mb-3">
            <Form.Label>Select Unique ID:</Form.Label>
            <Select
              options={uniquePartIds}
              onChange={(selectedOption) => setUniqueIdFilter(selectedOption)} // Update uniqueIdFilter state
              isClearable
              placeholder="Select Part Unique ID..."
            />
          </Form.Group>
        )}

        {/* Conditional Render: Status Filter */}
        {mainFilter === "status" && (
          <Form.Group controlId="filterStatus" className="mb-3">
            <Form.Label>Filter by Status:</Form.Label>
            <Form.Control
              as="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)} // Update statusFilter state
            >
              <option value="all">All Statuses</option>
              <option value="completed">Completed Parts</option>
              <option value="rejected">Rejected Parts</option>
              <option value="in progress">In Progress Parts</option>
            </Form.Control>
          </Form.Group>
        )}
      </Card.Body>
    </Card>
  );
}

export default PartFilter;
